import BlueLink from "../components/link/blue-link"
import ContentLayout from "../layouts/content-layout"
import { HEADER_LINKS } from "../menus"
import IDataPageProps from "../interfaces/data-page-props"
import * as React from "react"
import Seo from "../layouts/seo"

export default function Page({ data, location }: IDataPageProps) {
  return (
    <ContentLayout title="Site Map" location={location}>
      <></>
      <div className="mt-8 grid grid-cols-2 lg:grid-cols-3">
        <section>
          <h3 className="mt-4 font-semibold">Learn More</h3>
          {HEADER_LINKS.map((link: any, i: number) => {
            return (
              <div className="mt-2" key={i}>
                <BlueLink ariaLabel={`Goto ${link.name}`} href={link.url}>
                  {link.name}
                </BlueLink>
              </div>
            )
          })}
        </section>

        <section>
          <h3 className="mt-4 font-semibold">About</h3>

          <div className="mt-2">
            <BlueLink href="/privacy" ariaLabel={`View privacy information`}>
              Privacy policy
            </BlueLink>
          </div>
          <div className="mt-2">
            <BlueLink href="/terms" ariaLabel={`View terms and conditions`}>
              Terms of use
            </BlueLink>
          </div>

          <div className="mt-2">
            <BlueLink href="/help" ariaLabel={`View help information`}>
              Help
            </BlueLink>
          </div>

          {/* <div className="mt-2">
          <BlueLink href="/contact" ariaLabel={`Contact us`}>
            Contact us
          </BlueLink> 
        </div>*/}
        </section>
      </div>
    </ContentLayout>
  )
}

export const Head = () => <Seo title="Site Map" />
